import React from "react"

import Layout from "../components/layout"
import { FormattedMessage } from "gatsby-plugin-intl"

const Success = () => (
  <Layout>
    <h1><FormattedMessage id="success.title" /></h1>
    <p><FormattedMessage id="success.content" /></p>
  </Layout>
)

export default Success
